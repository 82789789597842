<template>
  <v-container fluid>
    <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> {{user.username}} </v-list-item-title>
          <v-list-item-subtitle>{{user.role}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Acerca de", icon: "mdi-information", path: "/option/about" },
      { text: "Cerrar sesión", icon: "mdi-door-closed", path: "/option/close" },
    ],
    user:{
      username: null,
      role: null,
    }
  }),
  mounted() {
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      let native_user = this.$store.getters.getUser;

      this.user.username = native_user.username;
      this.user.role = native_user.role;
    },
    newRegister() {
      this.$router.push("/register/new");
    },
  },
};
</script>
